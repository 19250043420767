import React from 'react';
import './css/style.css';
import Menu from './components/menu';
import Bs from './components/bs';
import {HelmetProvider} from 'react-helmet-async';
import Home from './components/home';
import Productions from './components/productions';
import Contact from './components/contact';

interface Props {
    
}

interface State {
    currentPage: string,
    scrolling: boolean,
    allPagesLoaded: boolean,
    locale: string,
    pagesLoaded: Map<string, boolean>,
    scrollTimeout?: NodeJS.Timeout,
    production: string
}

class App extends React.Component<Props, State> {

    state: State = {
        currentPage: "",
        scrolling: false,
        allPagesLoaded: false,
        locale: 'en',
        pagesLoaded: new Map<string, boolean>([
            ["home", false],
            ["productions", false],
            ["contact", false]
        ]),
        scrollTimeout: undefined,
        production: ""
    }

    componentDidMount() {

        if(window.location.pathname.includes('belgiescherpgesteld'))
        {
            // Get locale from url
            var params = window.location.pathname.slice(1).split("/"); // Remove leading slash and split on subsequent slashes
            var locale = "en";

            if(params[0] === "en" || params[0] === "fr" || params[0] === "nl" )
            {
                locale = params[0];
            }

            this.setState({currentPage: 'belgiescherpgesteld', locale: locale});
        }
        else
        {
            // Set scroll event listener
            window.addEventListener('scroll', this.updatePageFromScroll);
        }
    }

    updatePageFromScroll = () => {

        if(!this.state || this.state.scrolling) return;

        //console.log('scrolling: ' + window.scrollY);
        const elements = document.getElementsByClassName("page");
        if(elements)
        {
            Array.from(elements).forEach(element => {
                const elementY = element.getBoundingClientRect().top + window.scrollY + 0;
                if(window.scrollY > elementY - 200 && window.scrollY < elementY + 200)
                {

                    // locale
                    var locale = "/" + this.state.locale;
                    if(locale === "/en") locale = "";

                    // page
                    var page = element.id;
                    if(page === "home") page = "";
                    this.setState({currentPage: page});

                    // page url is
                    var path = locale + "/" + page;
                    if(page === 'production') path = locale + "/productions/" + this.state.production

                    if(window.location.pathname !== path)
                    {
                        //console.log("Setting page: " + path + " / " + page);
                        window.history.pushState({}, "", path);
                    }
                }
            });
        }
        
    }

    setCurrentPage = (page: string) => {

        // locale
        var locale = "/" + this.state.locale;
        if(locale === "/en") locale = "";

        // Set url
        var history = page;
        if (!history) history = "";
        window.history.pushState({}, "", locale + "/" + history); // Set url in browser

        // Clear the previous timeout if it is still running
        clearTimeout(this.state.scrollTimeout); 
        
        // Set State (pass the timeout to disable 'is scrolling' to scrollTimeout so we can clear it)
        this.setState({currentPage: page, scrolling: true, scrollTimeout: setTimeout(() => this.setState({scrolling: false}), 800)}, () =>{
            this.scrollToPage(page, 'smooth', true);
        });
    }

    scrollToPage = (page: string, behavior: string = 'smooth', offset: boolean = false) => {
        // Scroll to the page
        var y = 0;
        const element = document.getElementById(page);
        if (element) y = element.getBoundingClientRect().top + window.scrollY;
        if (offset) y += 0;
        window.scroll({
            top: y,
            behavior: behavior as ScrollBehavior
        });
    }

    setPageLoaded = (page: string) =>
    {
        if(this.state.allPagesLoaded) return;

        // Set page to loaded
        this.state.pagesLoaded.set(page, true);

        // Check if all pages are loaded
        let allLoaded = true;
        this.state.pagesLoaded.forEach((value: boolean, key: string) => {
            if(!value) allLoaded = false;
            //console.log("loaded: " + key + " > " + value);
        });

        // Once all pages are loaded
        if(allLoaded)
        {
            console.log('Pages loaded: Scrolling to page from url');

            var params = window.location.pathname.slice(1).split("/"); // Remove leading slash and split on subsequent slashes
            var locale = "en";
            var newPage = "";
            var production = "";

            if(params[0] === "en" || params[0] === "fr" || params[0] === "nl" )
            {
                locale = params[0];
                newPage = params[1];
                production = params[2];
            }
            else
            {
                newPage = params[0];
                production = params[1];
            }

            if(production)
            {
                this.setState({
                    allPagesLoaded: true,
                    locale: locale,
                    currentPage: newPage, 
                    production: production
                }, () => {
                    // Update html class
                    document.getElementsByTagName('html')[0].setAttribute('class', locale);
                    // Scroll handled by production componentDidUpdate
                });
            }
            else
            {
                this.setState({
                    allPagesLoaded: true,
                    locale: locale,
                    currentPage: newPage, 
                    scrolling: false
                }, () => {
                    // Update html class
                    document.getElementsByTagName('html')[0].setAttribute('class', locale);
                    this.scrollToPage(newPage, 'auto', true);
                });
            }
        }
    }

    setProduction = (production: string) =>
    {
        if(production === this.state.production)
        {
            // Scroll to production
            this.scrollToPage("production");
            return;
        }

        // locale
        var locale = "/" + this.state.locale;
        if(locale === "/en") locale = "";

        // Set url
        var history = 'productions/' + production;
        if (history === 'productions/') history = "productions";
        window.history.pushState({}, "", locale + "/" + history); // Set url in browser

        // Clear the previous timeout if it is still running
        clearTimeout(this.state.scrollTimeout); 
        
        // Set State (pass the timeout to disable 'is scrolling' to scrollTimeout so we can clear it)
        this.setState({production: production, scrolling: true, scrollTimeout: setTimeout(() => this.setState({scrolling: false}), 800)}, () =>{
            // scrolling handled by componentDidUpdate of production
        });
    }

    setLocale = (locale: string) =>
    {
        this.setState({
            locale: locale
        }, () => {
            // update page
            this.setCurrentPage(this.state.currentPage);
            // Update html class
            document.getElementsByTagName('html')[0].setAttribute('class', locale);
        });
    }

    render()
    {
        return (
            <div className={`app ${this.state.locale}`}>
                <HelmetProvider>
                    {this.state.currentPage === 'belgiescherpgesteld' &&
                        <Bs setLocale={this.setLocale} locale={this.state.locale} />
                    }
                    {this.state.currentPage !== 'belgiescherpgesteld' &&
                        <div>
                            <Menu currentPage={this.state.currentPage} setCurrentPage={this.setCurrentPage} setLocale={this.setLocale} locale={this.state.locale} />
                            <Home setProduction={this.setProduction} setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                            <Productions production={this.state.production} setProduction={this.setProduction} scrollToPage={this.scrollToPage} setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                            <Contact setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                        </div>
                    }
                </HelmetProvider>
            </div>
        )
    }
}

export default App;