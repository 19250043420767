import './../css/productions.css';
import axios from 'axios';
import React from 'react';
// @ts-ignore
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import ReactMarkdown from 'react-markdown';
// import * as _ from "lodash";

interface Props {
    locale: string,
    setPageLoaded: Function,
    production: string,
    setProduction: any,
    scrollToPage: Function
}

interface State {
    content: any,
    productionsList: any,
    productionPage: any,
    production: any,
    productions: any,
    slidesPerView: number,
    slidesOffset: number
}

class Productions extends React.Component<Props, State> {
 
    state: State = {
        content: null,
        productionsList: null,
        productionPage: null,
        production: null,
        productions: null,
        slidesPerView: 5,
        slidesOffset:407
    }

    componentDidMount() {

        Fancybox.bind("[data-fancybox]", {
            Thumbs: {
                autoStart: false,
            }
        });

        //Carousel.Plugins.Autoplay = Autoplay;
        this.getProductions(this.props.locale);
        this.getProductionPage(this.props.locale);

        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize = () => {
        if(window.innerWidth <= 750)
        {
            if(this.state.slidesPerView !== 1)
            {
                this.setState({
                    slidesPerView: 1,
                    slidesOffset: 0
                });
            }
        }
        else if(window.innerWidth <= 1100)
        {
            if(this.state.slidesPerView !== 2)
            {
                this.setState({
                    slidesPerView: 2,
                    slidesOffset: 0
                });
            }
        }
        else if(window.innerWidth <= 1333)
        {
            if(this.state.slidesPerView !== 3)
            {
                this.setState({
                    slidesPerView: 3,
                    slidesOffset: 0
                });
            }
        }
        else
        {
            if(this.state.slidesPerView !== 5)
            {
                this.setState({
                    slidesPerView: 5,
                    slidesOffset: 407
                });
            }
        }
    }

    getProductions = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/productions?populate=deep&sort=order&locale=' + locale)
            .then((response: any) => { 

                var productions: any[] = [];
                var index: number = 0;

                var productionsMultiple: any[] = [];

                var productionsList: any[][] = [];
                var column: number = 0;
                var row: number = 0;

                // Loop through productions and add them to the array
                response.data.data.forEach((production: any) => {
                    productions[index] = production;
                    index++;
                });

                // Sort array by random
                // productions = _.shuffle(productions);

                // Make it a multiple of 3
                productionsMultiple = productions;
                if(productionsMultiple.length % 3 !== 0) productionsMultiple = productionsMultiple.concat(productions);
                if(productionsMultiple.length % 3 !== 0) productionsMultiple = productionsMultiple.concat(productions);

                // Loop through productions and add them to productionsList in rows and column form
                productionsMultiple.forEach((production: any) => {
                    if(!productionsList[column]) productionsList[column] = [];
                    productionsList[column][row] = production;
                    row++;
                    if(row >= 3)
                    {
                        column++;
                        row = 0;
                    }
                });
                
                this.setState({
                    content: response.data.data.attributes,
                    productionsList: productionsList,
                    productions: productions
                }, () => { 
                    this.props.setPageLoaded("productions") 
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getProductions('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    getProductionPage = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/productionspage?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    productionPage: response.data.data.attributes,
                }, () => { 
                    this.props.setPageLoaded("productions") 
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getProductionPage('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    getProduction = (slug:string, locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/productions?filters[slug][$eq]=' + slug + '&populate=*&locale=' + locale)
            .then((response: any) => { 
                console.log(response.data);
                this.setState({
                    production: response.data.data[0].attributes
                }, () => { 
                    this.props.setPageLoaded("production");
                    this.props.scrollToPage("production"); // Once the production is loaded > scroll to that section
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getProduction(slug, 'en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    nextProduction = () => {
        var index: number = this.state.productions.findIndex((production:any) => production.attributes.slug === this.props.production);
        index++;
        if(index >= this.state.productions.length) index = 0;
        this.props.setProduction(this.state.productions[index].attributes.slug);
    }

    previousProduction = () => {
        var index: number = this.state.productions.findIndex((production:any) => production.attributes.slug === this.props.production);
        index--;
        if(index < 0) index = this.state.productions.length - 1;
        this.props.setProduction(this.state.productions[index].attributes.slug);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getProductions(this.props.locale);
            this.getProductionPage(this.props.locale);
            if(this.props.production) this.getProduction(this.props.production, this.props.locale);
        }

        if(prevProps.production !== this.props.production)
        {
            this.getProduction(this.props.production, this.props.locale);
        }
    }

    render() {
        return (
            <div id="productions" className='page'> 
                {this.state.productionsList && this.state.productionPage &&
                    <div>
                        <div id='prod-arrows' className='arrows content'>           
                            <div id='prod-arrow-left' className='arrowLeft'></div>
                            <div id='prod-arrow-right' className='arrowRight'></div>
                        </div>
                        <div className='arrowContent title'>
                            <h2>{this.state.productionPage.title}</h2>
                        </div>
                        <div id='prod-swiper-screen'>
                            <div id='prod-swiper-container'>
                                <Swiper id='prod-swiper' 
                                    modules={[Navigation]} 
                                    grabCursor={true} 
                                    loop={true} 
                                    slidesPerView={this.state.slidesPerView}
                                    spaceBetween={21}
                                    slidesOffsetBefore={this.state.slidesOffset}
                                    navigation={{
                                        nextEl: '#prod-arrow-right',
                                        prevEl: '#prod-arrow-left',
                                    }}
                                    >
                                    {this.state.productionsList.map((column: any, id: number) => 
                                        <SwiperSlide key={id}>
                                            {column.map((production: any) => 
                                                <div key={`${id}-${production.id}`} className='prod-production' 
                                                    style={production.attributes.featuredImage.data.attributes.formats.medium ?
                                                        { backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + production.attributes.featuredImage.data.attributes.formats.medium.url + ')'} :
                                                        { backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + production.attributes.featuredImage.data.attributes.url + ')'}
                                                        }>
                                                    <a className='prod-production-overlay' 
                                                        href={this.props.locale === 'en' ? "/productions/" + production.attributes.slug : "/" + this.props.locale + "/productions/" + production.attributes.slug}
                                                        onClick={(event) => { event.preventDefault(); this.props.setProduction(production.attributes.slug) }}
                                                        >
                                                        <div className='prod-production-title'>{production.attributes.title}</div>
                                                        <div className='prod-production-subTitle'>{production.attributes.subTitle}</div>
                                                    </a>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    )}  
                                </Swiper>
                            </div>
                            <div id='prod-fade'>
                                <div id='prod-fade-left'></div>
                                <div id='prod-fade-right'></div>
                            </div>
                        </div>
                        {this.state.production && 
                            <div id='production' className='content page'>
                                <div className='line'></div>
                                <div id='prod-single-arrows' className='arrows content'>           
                                    <div id='prod-single-arrow-left' onClick={() => { this.previousProduction() }} className='arrowLeft'></div>
                                    <div id='prod-single-arrow-right' onClick={() => { this.nextProduction() }} className='arrowRight'></div>
                                </div>
                                <div className='arrowContent title'>
                                    <h2>{this.state.production.title}</h2>
                                </div>
                                <div id='prod-single-gallery'>
                                    {this.state.production.trailerVimeoID &&
                                        <div data-fancybox="gallery" 
                                            data-src={'https://www.vimeo.com/' + this.state.production.trailerVimeoID} 
                                            className='prod-single-image prod-single-trailer'
                                            >
                                            <div className='prod-single-trailer-background'
                                                style={this.state.production.featuredImage.data.attributes.formats.medium ?
                                                    { backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + this.state.production.featuredImage.data.attributes.formats.medium.url + ')'} :
                                                    { backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + this.state.production.featuredImage.data.attributes.url + ')'}
                                                    }>
                                            </div>
                                            <div className='prod-single-trailer-play'></div>
                                        </div>
                                    }
                                    {this.state.production.gallery.data && this.state.production.gallery.data.map((image: any) => 
                                        <div data-fancybox="gallery" 
                                            data-src={image.attributes.formats.large ? process.env.REACT_APP_STRAPI_URL + image.attributes.formats.large.url : process.env.REACT_APP_STRAPI_URL + image.attributes.url } 
                                            key={image.id} 
                                            className='prod-single-image' 
                                            style={image.attributes.formats.medium ?
                                                { backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + image.attributes.formats.medium.url + ')'} :
                                                { backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + image.attributes.url + ')'}
                                                }>
                                        </div>
                                    )}
                                </div>
                                <div id='prod-single-content'>
                                    <div id='prod-single-synopsis'>
                                        <h3>{this.state.productionPage.synopsisTitle}</h3>
                                        <ReactMarkdown>{this.state.production.synopsis}</ReactMarkdown>
                                        {this.state.production.website &&
                                            <a className='button prod-single-website' href={this.state.production.website} rel='noreferrer' target='_blank'>{this.state.productionPage.websiteButtonLabel}</a>
                                        }
                                        </div>
                                    <div id='prod-single-credits'>
                                        <h3>{this.state.productionPage.creditsTitle}</h3>
                                        {this.state.production.credits &&
                                            <table>
                                                <tbody>
                                                    {this.state.production.credits.map((credit: any) =>
                                                        <tr key={credit.id}><td>{credit.title}</td><td>{credit.name}</td></tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                
            </div>
        )
    }
}

export default Productions;