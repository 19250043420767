import './../css/contact.css';
import axios from 'axios';
import React from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any
}

class Contact extends React.Component<Props, State> {
 
    state: State = {
        content: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/contact?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { 
                    this.props.setPageLoaded("contact") 
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return (
            <div id="contact" className='page'> 
                {this.state.content &&
                    <div className='content'>
                        <div className='line'></div>
                        <div className='contact-wrap'>
                            <h2>{this.state.content.title}</h2>
                            <div className='contact-quarter'>
                                <h3>{this.state.content.infoTitle}</h3>
                                <ReactMarkdown>{this.state.content.infoText}</ReactMarkdown>
                            </div>
                            <div className='contact-quarter'>
                                <h3>{this.state.content.newsletterTitle}</h3>
                                <ReactMarkdown>{this.state.content.newsletterText}</ReactMarkdown>
                                <a className='button' href={this.state.content.newsletterButtonUrl} rel="noreferrer" target="_blank">{this.state.content.newsletterButtonLabel}</a>
                            </div>
                            <div className='contact-half'>
                                <h3>{this.state.content.contactTitle}</h3>
                                <ReactMarkdown>{this.state.content.contactText}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Contact;