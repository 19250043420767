import './../css/home.css';
import axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

interface Props {
    locale: string,
    setPageLoaded: Function,
    setProduction: Function
}

interface State {
    content: any
}

class Home extends React.Component<Props, State> {
 
    state: State = {
        content: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/home?populate=deep&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { 
                    this.props.setPageLoaded("home") 
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return (
            <div id="home" className='page'> 
                {this.state.content &&
                    <div>
                        <Helmet>
                            {/* <title>{this.state.content.metaTitle}</title>
                            <meta property="og:title" content={this.state.content.metaTitle} />
                            <meta name="description" content={this.state.content.metaDescription} />
                            <meta property="og:description" content={this.state.content.metaDescription} />
                            <meta property="og:image" content={process.env.REACT_APP_STRAPI_URL + this.state.content.metaImage.data.attributes.url} /> */}
                        </Helmet>
                        <Swiper id='home-productions' 
                            modules={[Autoplay,Navigation]} 
                            grabCursor={true} 
                            loop={true} 
                            autoplay={{ delay: 4000 }}
                            navigation={{
                                nextEl: '#home-arrow-right',
                                prevEl: '#home-arrow-left',
                              }}
                            >
                            {this.state.content.productions.map((production: any) => 
                                <SwiperSlide key={production.id}> 
                                    <div className='home-production' style={production.production.data.attributes.featuredImage.data.attributes.formats.large ?
                                        { backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + production.production.data.attributes.featuredImage.data.attributes.formats.large.url + ')'} :
                                        { backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + production.production.data.attributes.featuredImage.data.attributes.url + ')'}
                                        }>
                                        <div className='home-production-overlay'>
                                            <div className='arrowContent'>
                                                <div className='home-production-title'>
                                                    {production.production.data.attributes.title}
                                                    <a href={this.props.locale === 'en' ? "/productions/" + production.production.data.attributes.slug : "/" + this.props.locale + "/productions/" + production.production.data.attributes.slug}
                                                        onClick={(event) => { event.preventDefault(); this.props.setProduction(production.production.data.attributes.slug) }} 
                                                        className='button'>
                                                            {this.state.content.infoButtonLabel}
                                                    </a>
                                                </div>
                                                <div className='home-production-subTitle'>{production.production.data.attributes.subTitle}</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <div id='home-arrows' className='arrows content'>           
                            <div id='home-arrow-left' className='arrowLeft'></div>
                            <div id='home-arrow-right' className='arrowRight'></div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Home;