import './../css/menu.css';
import axios from 'axios';
import React from 'react';

interface Props {
    locale: string,
    currentPage: string,
    setCurrentPage: Function,
    setLocale: Function
}

interface State {
    menu: any,
    locales: any,
    hamburger: boolean
}

class Menu extends React.Component<Props, State> {
 
    state: State = {
        menu: null,
        locales: null,
        hamburger: false
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/menus?filters[name][$eq]=main&populate=*&locale=' + locale)
            .then((response: any) => {
                this.setState({
                    menu: response.data.data[0].attributes
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    clickMenuItem = (page: string) => 
    {
        this.props.setCurrentPage(page);
        this.setState({hamburger: false});
    }

    render()
    {
        return (
            <div id="menu" className={this.state.hamburger ? 'open' : ''}> 
                {this.state.menu && 
                    <div id="menu-main">
                        <div className='content'>
                            <div id="menu-main-hamburger">
                                <div onClick={() => this.setState({hamburger: !this.state.hamburger})}>{this.state.menu.title}</div>
                            </div>
                            <h1 onClick={(event) => {event.preventDefault(); this.clickMenuItem("")}}>Las Belgas</h1>
                            {this.state.menu.menuItems.map((menuItem: any) => 
                                <a key={menuItem.id} 
                                    className={this.props.currentPage === menuItem.slug || (!this.props.currentPage && !menuItem.slug) ? "active" : ""} 
                                    href={menuItem.slug ? menuItem.slug : "/"}
                                    onClick={(event) => {event.preventDefault(); this.clickMenuItem(menuItem.slug)}}>
                                        {menuItem.label}
                                </a>
                            )}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Menu;