import 'normalize.css';
import './../css/bs.css';
import axios from 'axios';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import LaurelDocville from './../img/bs/laurel-docville.png';
import LaurelDokumentart from './../img/bs/laurel-dokumentart.png';
import LaurelHavanhumo from './../img/bs/laurel-havanhumo.png';
import { Helmet } from 'react-helmet-async';

interface Props {
    locale: string,
    setLocale: Function
}

interface State {
    content: any,
    locales: any
}

class Bs extends React.Component<Props, State> {
 
    state: State = {
        content: null,
        locales: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);

        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/i18n/locales')
            .then(({ data }) => {
                this.setState({
                    locales: data
                });
            })
            .catch((error) => console.log(error))
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/belgie-scherpgesteld?populate=deep&locale=' + locale)
            .then((response: any) => {
                this.setState({
                    content: response.data.data.attributes
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render()
    {
        return (
            <div id="bs"> 
                <Helmet>
                    <title>België Scherpgesteld</title>
                </Helmet>
                {this.state.locales && 
                    <div id="bs-language">
                        {this.state.locales.map((lang: any) => 
                            <button key={lang.id} 
                                className={this.props.locale === lang.code ? "active" : ""} 
                                onClick={() => this.props.setLocale(lang.code)}>
                                    {lang.code}
                            </button>
                        )}
                    </div>            
                }
                {this.state.content && 
                    <div>
                        <div className="bs-container">
                            <div className="bs-separator-50"></div>
                            <div id="bs-title">{this.state.content.title}</div>
                            <div id="bs-subtitle">{this.state.content.subTitle}</div>
                            <div id="bs-awards">
                                <img src={LaurelHavanhumo} alt="Ha Van HUMO" height="96" />
                                <img src={LaurelDocville} alt="Docville" height="96" />
                                <img src={LaurelDokumentart} alt="Dokumentart" height="96" />
                            </div>
                            <div id="bs-trailer">
                                <iframe title="bs-trailer" src="https://player.vimeo.com/video/190427983?title=0&amp;byline=0&amp;portrait=0" width="650" height="365" frameBorder="0" allowFullScreen></iframe>
                            </div>
                            <div id="bs-description"><ReactMarkdown>{this.state.content.description}</ReactMarkdown></div>
                            <div className="bs-separator-40"></div>
                        </div>
                        <div className="bs-black">
                            <div className="bs-container">
                                {this.state.content.episodes.map((episode: any) => 
                                    <div key={episode.id} className="bs-episode">
                                        <div className="bs-episode-title">
                                            {episode.title}
                                            <a className="bs-episode-button" target="_blank" rel="noreferrer" href={episode.buttonUrl}>{episode.buttonLabel}</a>
                                        </div>
                                        <div className="bs-episode-flex">
                                            <div className="bs-episode-image"><img src={process.env.REACT_APP_STRAPI_URL + episode.image.data.attributes.formats.medium.url} alt={episode.title} /></div>
                                            <div className="bs-episode-description"><ReactMarkdown>{episode.description}</ReactMarkdown></div>
                                        </div>
                                        <div className="bs-clear"></div>
                                    </div>
                                )}
                            </div>
                            <div className="bs-separator-40"></div>
                        </div>

                        <div className="bs-footer">
                            <div className="bs-separator-40"></div>
                            <ReactMarkdown>{this.state.content.footer}</ReactMarkdown>
                            <div className="bs-separator-40"></div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Bs;